"use client"

import { Fragment, useEffect, useState } from "react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { Dialog, Menu, Popover, Tab, Transition } from "@headlessui/react"
import axios from "axios"
import _ from "lodash"
import { signOut, useSession } from "next-auth/react"
import { HiOutlineShoppingBag } from "react-icons/hi2"
import { LuHeart, LuMenu, LuUser } from "react-icons/lu"
import { RiArrowDropDownLine } from "react-icons/ri"

import { getCartCount } from "@/lib/redux/Slices/CartSlice"
import { useAppDispatch, useAppSelector } from "@/lib/redux/store"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "@/components/ui/menubar"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Separator } from "@/components/ui/separator"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"

import { Button } from "../../ui/button"
import Search from "./search"

const navigation = {
  categories: [
    {
      name: "Men",
      clothing: [
        {
          name: "T-Shirts",
          href: "/products?apparel=TSHIRTS&fit=MEN",
        },
        { name: "Hoodies", href: "/products?apparel=HOODIES&fit=MEN" },
        {
          name: "Oversized T-Shirts",
          href: "/products?apparel=OVERSIZEDTSHIRTS&fit=MEN",
        },
        { name: "All", href: "/products?fit=MEN" },
      ],

      categories: [
        { name: "Upcoming", href: "/products" },
        {
          name: "Just Added",
          href: "/products?fit=MEN&sort=newest",
        },
        {
          name: "Designer Collection",
          href: "/products?fit=MEN&isDesignerCollection=true",
        },
      ],
    },
    {
      name: "Women",
      clothing: [
        {
          name: "T-Shirts",
          href: "/products?apparel=TSHIRTS&fit=WOMEN",
        },
        {
          name: "Hoodies",
          href: "/products?apparel=HOODIES&fit=WOMEN",
        },
        {
          name: "Oversized T-Shirts",
          href: "/products?apparel=OVERSIZEDTSHIRTS&fit=WOMEN",
        },
        { name: "All", href: "/products?fit=WOMEN" },
      ],

      categories: [
        { name: "Upcoming", href: "/products" },
        {
          name: "Just Added",
          href: "/products?fit=WOMEN&sort=newest",
        },
        {
          name: "Designer Collection",
          href: "/products?fit=WOMEN&isDesignerCollection=true",
        },
      ],
    },
  ],
  other: [
    { name: "Limited Edition", href: "/products?isLimitedEdition=true" },
    { name: "More", href: "/products" },
  ],
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export default function MainHeader() {
  const { data: session } = useSession()
  const [cartCount, setCartCount] = useState(0)
  const router = useRouter()
  const dispatch = useAppDispatch()
  const cc = useAppSelector((state) => state.cart.count)

  const source = [
    {
      title: "Title 1",
      description: "Description 1",
      image: "https://via.placeholder.com/150",
      price: "$10",
    },
    {
      title: "Title 2",
      description: "Description 2",
      image: "https://via.placeholder.com/150",
      price: "$20",
    },
  ]

  const [search, setSearch] = useState({
    isLoading: false,
    results: [],
    value: "",
  })

  const handleResultSelect = ({ result }: any) =>
    setSearch({ ...search, value: result.title })

  const handleSearchChange = (value: any) => {
    setSearch({ ...search, isLoading: true, value })
    console.log(value)

    setTimeout(() => {
      if (value.length < 1)
        return setSearch({ isLoading: false, results: [], value: "" })

      const re = new RegExp(_.escapeRegExp(value), "i")
      const isMatch = (result: { title: string }) => re.test(result.title)

      setSearch({
        ...search,
        isLoading: false,
        results: _.filter(source, isMatch) as any,
      })
    }, 300)
  }

  // const getCartCount = async () => {
  //   try {
  //     const res = await axios.get("/api/cart?countOnly=true")
  //     if (res.status === 200) {
  //       setCartCount(res.data.data.count)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    //getCartCount()
    dispatch(getCartCount())
  }, [])

  return (
    <div className="bg-white sticky top-0 z-[50]">
      <header className="relative bg-white z-10">
        <nav aria-label="Top" className="mx-auto max-w-7xl sm:px-6 lg:px-0">
          <div className="border-b border-gray-200 px-4 sm:px-0 sm:pb-0">
            <div className="flex h-16 items-center justify-between">
              <div className="md:hidden flex gap-2 items-center">
                <Sheet>
                  <SheetTrigger asChild>
                    <Button className="px-2 py-1" variant="ghost">
                      <LuMenu className="" size={20} />
                    </Button>
                  </SheetTrigger>
                  <SheetContent side="left" className="p-0 h-screen">
                    <SheetHeader className="pt-2.5">
                      <SheetTitle className="text-center font-bold">
                        {session ? (
                          <div className="flex w-max items-center justify-between">
                            <Menubar className="border-none">
                              <MenubarMenu>
                                <MenubarTrigger>
                                  <Button
                                    size="icon"
                                    className="relative rounded-full bg-white hover:bg-white flex text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                  >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">
                                      Open user menu
                                    </span>
                                    <img
                                      className="h-8 w-8 rounded-full"
                                      src={session.user.image ?? ""}
                                      alt=""
                                      width={50}
                                      height={50}
                                    />
                                  </Button>
                                </MenubarTrigger>
                                <MenubarContent>
                                  <MenubarItem>
                                    <a
                                      href="/profile"
                                      className={classNames(
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      Your Profile
                                    </a>
                                  </MenubarItem>
                                  <MenubarSeparator />
                                  <MenubarItem>
                                    <a
                                      href="/orders"
                                      className={classNames(
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      Orders & Returns
                                    </a>
                                  </MenubarItem>
                                </MenubarContent>
                              </MenubarMenu>
                            </Menubar>

                            <Menu as="div">
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/cart"
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        View Cart
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/wishlist"
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        Wishlist
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/profile"
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        Your Profile
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/orders"
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        Orders & Returns
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() => {
                                          signOut()
                                          router.push("/")
                                        }}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                        )}
                                      >
                                        Sign out
                                      </button>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                            <p className="-ms-2">{session.user.name}</p>
                          </div>
                        ) : (
                          <Link href="/">
                            <span className="sr-only">Alien Wardrobe</span>
                            <img
                              className="h-8 w-auto ml-6"
                              src="/assets/images/logo.svg"
                              alt="Alien Wardrobe"
                              height={50}
                              width={50}
                            />
                          </Link>
                        )}
                      </SheetTitle>
                    </SheetHeader>
                    <div className="h-[92vh] flex flex-col justify-between items-center py-4">
                      <div className="w-full">
                        <Accordion
                          type="single"
                          collapsible
                          className="w-full px-6 rounded-none"
                        >
                          {navigation.categories.map(
                            (category, categoryIdx) => (
                              <AccordionItem
                                key={categoryIdx}
                                value={`item-${categoryIdx}`}
                              >
                                <AccordionTrigger className="text-center">
                                  {category.name}
                                </AccordionTrigger>
                                <AccordionContent className="text-center">
                                  <ScrollArea className="w-full grid grid-cols-1 gap-x-6 gap-y-10 lg:gap-x-8">
                                    <div>
                                      {/* <p
                                        id="clothing-heading"
                                        className="font-medium text-gray-900"
                                      >
                                        Clothing
                                      </p> */}
                                      <div className="border-t ps-4 pt-4 border-gray-400 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                        <ul
                                          role="list"
                                          aria-labelledby="clothing-heading"
                                          className="space-y-6 sm:space-y-4"
                                        >
                                          {category.clothing.map((item) => (
                                            <li
                                              key={item.name}
                                              className="flex"
                                            >
                                              <a
                                                href={item.href}
                                                className="hover:text-gray-800"
                                              >
                                                {item.name}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                    {/* <div>
                                      <p
                                        id="categories-heading"
                                        className="font-medium text-gray-900"
                                      >
                                        Categories
                                      </p>
                                      <ul
                                        role="list"
                                        aria-labelledby="categories-heading"
                                        className="mt-4 space-y-6 border-t border-gray-200 pt-6 sm:space-y-4"
                                      >
                                        {category.categories.map((item) => (
                                          <li key={item.name} className="flex">
                                            <a
                                              href={item.href}
                                              className="hover:text-gray-800"
                                            >
                                              {item.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div> */}
                                  </ScrollArea>
                                </AccordionContent>
                              </AccordionItem>
                            )
                          )}
                        </Accordion>

                        {navigation.other.map((item) => (
                          <>
                            <Button
                              variant="ghost"
                              key={item.name}
                              className="w-full p-6 rounded-none font-medium"
                            >
                              <span className="font-medium w-full text-left">
                                {item.name}
                              </span>
                            </Button>
                            <Separator className="w-[88%]" />
                          </>
                        ))}
                      </div>
                      {session ? (
                        <Button
                          onClick={() => {
                            signOut()
                            router.push("/")
                          }}
                          type="button"
                          variant="outline"
                          className="px-4 py-2 rounded-full border-[2px] border-[#44444433] text-[#3D7654] hover:text-gray-500 lg:ml-4"
                        >
                          Logout
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            router.push("/login")
                          }}
                          type="button"
                          variant="outline"
                          className="px-4 py-2 rounded-full border-[2px] border-[#44444433] text-[#3D7654] hover:text-gray-500 lg:ml-4"
                        >
                          Login
                        </Button>
                      )}
                    </div>
                  </SheetContent>
                </Sheet>
              </div>

              {/* Logo */}
              <div className="flex md:flex-1 -ml-3">
                <Link href="/">
                  <span className="sr-only">Alien Wardrobe</span>
                  <img
                    className="h-8 w-auto"
                    src="/assets/images/logo.svg"
                    alt="Alien Wardrobe"
                    height={50}
                    width={50}
                  />
                </Link>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden md:block absolute inset-x-0 bottom-0 sm:static sm:flex-1 sm:self-stretch">
                <div className="text-nowrap flex h-14 space-x-8 overflow-x-auto border-t px-4 pb-px sm:h-full sm:justify-start sm:overflow-visible sm:border-t-0 sm:pb-0">
                  {navigation.categories.map((category, categoryIdx) => (
                    <Popover key={categoryIdx} className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "border-green-500 text-green-500"
                                  : "border-transparent text-gray-700",
                                "relative z-10 outline-none -mb-px hover:text-green-500 flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out text-nowrap"
                              )}
                            >
                              {category.name} <RiArrowDropDownLine />
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute inset-x-0 top-full text-gray-500 sm:text-sm">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div
                                className="absolute inset-0 top-1/2 bg-white shadow"
                                aria-hidden="true"
                              />

                              <div className="relative bg-white">
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                  <div className="grid grid-cols-1 items-start gap-x-6 gap-y-10 pb-12 pt-10 md:grid-cols-2 lg:gap-x-8">
                                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:gap-x-8">
                                      <div>
                                        <p
                                          id="clothing-heading"
                                          className="font-medium text-gray-900"
                                        >
                                          Clothing
                                        </p>
                                        <div className="mt-4 border-t border-gray-200 pt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                          <ul
                                            role="list"
                                            aria-labelledby="clothing-heading"
                                            className="space-y-6 sm:space-y-4"
                                          >
                                            {category.clothing.map((item) => (
                                              <li
                                                key={item.name}
                                                className="flex"
                                              >
                                                <a
                                                  href={item.href}
                                                  className="hover:text-gray-800"
                                                >
                                                  {item.name}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:gap-x-8">
                                      <div>
                                        <p
                                          id="categories-heading"
                                          className="font-medium text-gray-900"
                                        >
                                          Categories
                                        </p>
                                        <ul
                                          role="list"
                                          aria-labelledby="categories-heading"
                                          className="mt-4 space-y-6 border-t border-gray-200 pt-6 sm:space-y-4"
                                        >
                                          {category.categories.map((item) => (
                                            <li
                                              key={item.name}
                                              className="flex"
                                            >
                                              <a
                                                href={item.href}
                                                className="hover:text-gray-800"
                                              >
                                                {item.name}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.other.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-green-500 text-nowrap"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              <div className="flex md:flex-1 items-center justify-end">
                {/* Search */}
                {/* <a href="#" className="p-2 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Search</span>
                  <HiMagnifyingGlass className="h-6 w-6" aria-hidden="true" />
                </a> */}
                <Search className="hidden md:block" />

                {/* Wishlist */}
                {session && (
                  <Button
                    onClick={() => {
                      router.push("/wishlist")
                    }}
                    type="button"
                    variant="outline"
                    size="icon"
                    className="ms-2 md:ms-0 p-2 rounded-full border text-gray-600 hover:text-gray-500 lg:ml-4 hidden md:block"
                  >
                    <span className="sr-only">Wishlist</span>
                    <LuHeart className="h-5 w-5" aria-hidden="true" />
                  </Button>
                )}
                {/* Cart */}
                {session && (
                  <div className="ms-2 md:ms-0 relative">
                    <Button
                      onClick={() => {
                        router.push("/cart")
                      }}
                      type="button"
                      variant="outline"
                      size="icon"
                      className="p-2 rounded-full border text-gray-600 hover:text-gray-500 lg:ml-4 relative"
                    >
                      <HiOutlineShoppingBag
                        className="h-5 w-5"
                        aria-hidden="true"
                      />

                      <span className="sr-only">items in cart, view bag</span>
                    </Button>

                    <span className="absolute top-0 right-0 -mt-2 -mr-2 px-1.5 text-xs font-medium bg-gray-500 rounded-full text-white">
                      {cc}
                    </span>
                  </div>
                )}

                {/* Profile */}
                {session ? (
                  <>
                    {/* <button
                      className="p-2 text-gray-400 hover:text-gray-500 lg:ml-4"
                      onClick={() => signOut()}
                    >
                      <span className="sr-only">Logout</span>

                      <HiArrowLeftStartOnRectangle
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>
                    <Link
                      href="/profile"
                      className="p-2 text-gray-400 hover:text-gray-500 lg:ml-4"
                    >
                      <span className="sr-only">Profile</span>

                      <HiUserCircle className="h-6 w-6" aria-hidden="true" />
                    </Link> */}
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ms-4 hidden md:block">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={session.user.image ?? ""}
                            alt=""
                            width={50}
                            height={50}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/profile"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Your Profile
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/orders"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Orders & Returns
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  signOut()
                                  router.push("/")
                                }}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      router.push("/login")
                    }}
                    type="button"
                    variant="outline"
                    size="icon"
                    className="p-2 rounded-full border text-gray-600 hover:text-gray-500 lg:ml-4"
                  >
                    <span className="sr-only">Login</span>
                    <LuUser className="h-6 w-6" aria-hidden="true" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
